import { render, staticRenderFns } from "./InvestmentsCreateDemandModal.vue?vue&type=template&id=9b8fdaf8&scoped=true"
import script from "./InvestmentsCreateDemandModal.vue?vue&type=script&lang=js"
export * from "./InvestmentsCreateDemandModal.vue?vue&type=script&lang=js"
import style0 from "./InvestmentsCreateDemandModal.vue?vue&type=style&index=0&id=9b8fdaf8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b8fdaf8",
  null
  
)

export default component.exports